<template>
  <div v-if="session.user">
    <v-btn
      fixed
      bottom
      right
      fab
      small
      elevation="0"
      class="primary"
      @click="
        session.settings.show = !session.settings.show;
        updateSettings(session.settings);
      "
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-navigation-drawer right app clipped v-model="session.settings.show">
      <v-card flat>
        <v-card-title class="text-h5">
          settings
          <v-spacer></v-spacer>
          <v-btn
            @click="
              session.settings.show = false;
              updatesSettings(session.settings);
            "
            icon
            small
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text>
          <v-slider
            v-model="session.settings.boardSize"
            @click="updateSettings(session.settings)"
            label="Board size"
            min="3"
            max="18"
            step="3"
            thumb-color="primary"
            thumb-label="always"
          ></v-slider>
          <v-slider
            v-model="session.settings.increment"
            @click="updateSettings(session.settings)"
            label="Increment"
            min="1"
            max="6"
            thumb-color="primary"
            thumb-label="always"
          ></v-slider>
          <v-slider
            v-model="session.settings.timeToGuess"
            @click="updateSettings(session.settings)"
            label="Time to guess"
            min="1"
            max="20"
            thumb-color="primary"
            thumb-label="always"
          ></v-slider>
          Background
          <v-layout row>
            <v-flex col xs6 v-for="bg in backgrounds" :key="bg">
              <v-card
                clickable
                :elevation="session.settings.background == bg ? 3 : 0"
              >
                <v-img
                  :src="resolve(bg)"
                  @click="
                    session.settings.background = bg;
                    updatesSettings(session.settings);
                  "
                />
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-container>
      <v-card flat class="secondary darken-1" dark>
        <v-card-text>
          <div class="display-1 mb-5">Hi {{ session.user.email }}</div>
          <div v-if="session.games.length">
            <div class="text-center headline mb-4">High Scores</div>
            <v-simple-table class="accent primary--text">
              <thead class="primary">
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Minutes</th>
                  <th>Reveals</th>
                  <th>Opens</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in session.games" :key="g.dt">
                  <td class="font-weight-bold headline">{{ g.name }}</td>
                  <td>{{ new Date(g.dt).toLocaleDateString() }}</td>
                  <td>{{ g.duration }}</td>
                  <th>{{ g.reveals }}</th>
                  <th>{{ g.opens }}</th>
                  <th class="headline">{{ g.points }}</th>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div v-else>
            <strong>Welcome to Settacular</strong> - an online version of the
            card game SET. Set is a real-time card game designed by Marsha Falco
            in 1974. The deck consists of 81 unique cards that vary in four
            features across three possibilities for each kind of feature: number
            of shapes (one, two, or three), shape (diamond, squiggle, oval),
            shading (solid, striped, or open), and color (red, green, or
            purple).[1] Each possible combination of features (e.g. a card with
            three striped green diamonds) appears as a card precisely once in
            the deck.
            <br />
            <br />
            In the game, certain combinations of three cards are said to make up
            a set. For each one of the four categories of features — color,
            number, shape, and shading — the three cards must display that
            feature as either a) all the same, or b) all different. Put another
            way: For each feature the three cards must avoid having two cards
            showing one version of the feature and the remaining card showing a
            different version.
            <br />
            <br />
            For example, 3 solid red diamonds, 2 solid green squiggles, and 1
            solid purple oval form a set, because the shadings of the three
            cards are all the same, while the numbers, the colors, and the
            shapes among the three cards are all different.
            <br />
            <h3 class="mt-3 mb-3">How to play</h3>
            When you start the game, 12 cards from your deck will be opened. You
            create a SET by clicking on three cards that make up a SET. The
            completed SET is then moved to the sidebar and three new cards are
            added to the board. Here are are rules:
            <br />
            When you start creating a set, you only have 4 seconds to finish it.
            <ul class="mt-3 mb-3">
              <li>
                There is a timer in the single player. You can pause the timer
                by clicking on it. Cards will be hidden when the game is paused.
              </li>
              <li>
                You can reveal cards by clicking the 'eye' on the toolbar but
                you get no credit for that set
              </li>
              <li>
                If there are no sets, you can add three cards from the deck by
                clicking the add+3 [+] on the toolbar. The add+3 will add three
                new cards to the board. However, if there are already sets on
                the board when you click add+3, it will add 30 seconds to the
                timer.
              </li>
              <li>You can mute the volume.</li>
              <li>
                Multi-plater and the ability to save your games will be coming
                soon. in multi-player, you will be able to compete against
                friends or family.
              </li>
            </ul>

            Ready, SET, ... M&B
            <br />
            <br />
          </div>

          <div class="text-center mt-10">
            <v-btn rounded class="accent primary--text mb-5" x-large to="play"
              >Play a game</v-btn
            >
          </div>
        </v-card-text>

        <v-card-actions class="caption secondary lighten-1">
          This article uses material from the Wikipedia article
          <a href="https://en.wikipedia.org/wiki/Set_(card_game)" target="new"
            >Set (Card Game)</a
          >, which is released under the
          <a href="https://creativecommons.org/licenses/by-sa/3.0/" target="new"
            >Creative Commons Attribution-Share-Alike License 3.0</a
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "Home",

  components: {},

  data: () => ({
    name: null,

    level: 2,

    shapes: ["circle", "diamond", "square"],
    colors: ["orange", "blue", "black"],
    patterns: ["hatch", "solid", "outline"],
    numbers: [1, 2, 3],

    backgrounds: [
      "bush",
      "watercolor",
      "double-bubble",
      "double-bubble-outline",
      "moroccan-flower",
      "moroccan-flower-dark",
    ],
  }),

  mounted() {},

  computed: {
    ...mapState(["session"]),
  },

  methods: {
    ...mapMutations({
      endSession: "END_SESSION",
      updateSettings: "UPDATE_SETTINGS",
    }),

    resolve(img) {
      return require("../assets/" + img + ".png");
    },

    exit() {
      this.endSession();
    },
  },
};
</script>
