import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VueGtag from "vue-gtag";

//import "@aws-amplify/ui-vue";

//import Amplify from "aws-amplify";
//import awsconfig from "./aws-exports";
//Amplify.configure(awsconfig);

Vue.use(VueGtag, {
  config: { id: "G-8QWXZMSJZX" },
});

Vue.config.productionTip = false;

import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAiigehOHqEyGEJbcohL43cIQ2ovYM6Nvk",
  authDomain: "settacular.firebaseapp.com",
  databaseURL: "https://settacular-default-rtdb.firebaseio.com",
  projectId: "settacular",
  storageBucket: "settacular.appspot.com",
  messagingSenderId: "88223373309",
  appId: "1:88223373309:web:a06fb0b0352e038f132b94",
  measurementId: "G-H4XYTB1KLB",
};
firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
