import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    session: {
      user: null,
      games: [],

      settings: {
        mute: false,
        show: false,
        boardSize: 12,
        increment: 3,
        timeToGuess: 4,
        background: "double-bubble-outline",
      },
    },
  },

  mutations: {
    START_SESSION(state, payload) {
      state.session.user = payload.user;
      state.session.games = [];

      state.session.settings = {
        show: false,
        boardSize: 12,
        increment: 3,
        timeToGuess: 4,
        background: "double-bubble-outline",
      };
    },

    END_SESSION(state) {
      state.session.user = null;
      state.session.games = [];
    },

    SAVE_GAME(state, payload) {
      state.session.games.push(payload);
    },

    UPDATE_SETTINGS(state, payload) {
      state.session.settings = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
