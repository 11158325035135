<template>
  <div v-if="session.user">
    <v-app-bar clipped-left flat app dark class="primary">
      <v-toolbar-title class="hidden-sm-and-down">
        {{ session.user.email }}</v-toolbar-title
      >

      <v-btn
        fab
        small
        @click="paused = !paused"
        class="hidden-md-and-up accent lighten-3 primary--text"
      >
        <v-icon small v-if="paused">mdi-pause</v-icon>
        <span v-else>{{ timerDisplay }}</span>
      </v-btn>

      <v-spacer></v-spacer>

      <span
        class="hidden-md-and-up subtitle-1 white--text ml-2"
        @click="sidebar = true"
        >Sets: {{ foundSets.length - revealCount }}</span
      >
      <v-spacer></v-spacer>
      <v-card
        v-for="r of closedDeck"
        :key="'deck' + r"
        height="45"
        width="25"
        class="accent lighten-2 relative hidden-sm-and-down"
        style="margin-left: -15px"
      >
      </v-card>
      <v-card
        height="40"
        width="25"
        v-if="deck"
        class="
          accent
          lighten-2
          relative
          hidden-md-and-up
          text-center
          primary--text
          pt-2
        "
        >{{ remaining - session.settings.increment }}</v-card
      >

      <v-sheet class="ml-10"></v-sheet>
      <template v-if="remaining">
        <v-card
          v-for="r of session.settings.increment"
          :key="'flip' + r"
          @click="openMore"
          height="40"
          width="25"
          class="accent relative text-center pt-2 primary--text"
          style="margin-left: -15px"
        >
          <span v-if="r == session.settings.increment">+</span>
        </v-card>
      </template>
      <v-spacer></v-spacer>
      <v-btn small icon outlined @click="toggleMute" dark class="mr-2"
        ><v-icon small v-if="!session.settings.mute">mdi-volume-medium</v-icon>
        <v-icon small v-else>mdi-volume-variant-off</v-icon></v-btn
      >
      <v-btn small icon outlined @click="revealSet = true" dark class="mr-2"
        ><v-icon small>mdi-eye</v-icon></v-btn
      >
      <v-btn
        small
        icon
        outlined
        @click="restartConfirm = true"
        dark
        class="mr-2"
        ><v-icon small>mdi-reload</v-icon></v-btn
      >
      <v-btn small icon outlined @click="exitConfirm = true" dark
        ><v-icon small>mdi-close</v-icon></v-btn
      >
    </v-app-bar>

    <v-navigation-drawer
      v-model="sidebar"
      clipped
      app
      color="secondary darken-1"
      style="opacity: 0.85"
    >
      <v-toolbar class="secondary pt-5" extended>
        <v-avatar
          class="accent lighten-3 mt-2 ml-1 mr-2"
          size="80"
          style="font-size: 30px; opacity: 0.7 clickable"
          @click="paused = !paused"
        >
          <v-icon v-if="paused">mdi-pause</v-icon>
          <span class="primary--text" v-else>{{ timerDisplay }}</span>
        </v-avatar>
        <v-spacer></v-spacer>
        <v-simple-table class="secondary mt-1" dark dense>
          <tbody>
            <tr>
              <td class="caption">Found</td>
              <td>{{ foundSets.length - revealCount }}</td>
            </tr>
            <tr>
              <td class="caption">Revealed</td>
              <td>{{ revealCount }}</td>
            </tr>
            <tr>
              <td class="caption">Opens</td>
              <td>{{ openCount / session.settings.increment }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-toolbar>
      <div class="mt-10 pt-10 text-center">
        <div
          v-for="(found, setIndex) in foundSets"
          :key="'s' + setIndex"
          class="pr-3"
          style="margin-top: -65px"
          relative
        >
          <v-card
            v-for="(card, cardIndex) in found"
            :key="'c' + cardIndex"
            elevation="4"
            class="set-card-small"
          >
            <div class="mt-2 ml-2 mr-2 text-center">
              <shape
                style="width: 33%"
                v-for="(n, shapeIndex) in card.number"
                :key="'c' + shapeIndex"
                :id="`found-${setIndex}-${cardIndex}-${shapeIndex}`"
                :color="card.color"
                :pattern="card.pattern"
                :shape="card.shape"
              >
              </shape>
            </div>
          </v-card>
        </div>
      </div>
    </v-navigation-drawer>

    <v-layout class="pa-5" row wrap>
      <v-flex
        class="pa-1"
        xs4
        sm3
        md3
        lg2
        v-for="(card, cardIndex) in visible"
        :key="'ca' + cardIndex"
      >
        <v-card
          :style="cardStyle"
          clickable
          :class="{ shake: card.animated, selected: card.selected }"
          @click="select(cardIndex)"
        >
          <v-responsive :aspect-ratio="1 / 1.15">
            <div class="text-center pl-1 pr-1 pt-10" v-if="!paused">
              <shape
                style="width: 33%"
                v-for="(n, shapeIndex) in card.number"
                :key="'sh' + shapeIndex"
                :id="`main-${cardIndex}-${shapeIndex}`"
                :color="card.color"
                :pattern="card.pattern"
                :shape="card.shape"
              >
              </shape>
              <div>
                <v-btn icon v-if="card.reveal" class="red--text mb-5"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </div>
            </div>
          </v-responsive>
        </v-card>
      </v-flex>
    </v-layout>

    <v-snackbar
      centered
      style="opacity: 0.8"
      v-model="flash"
      :color="message.type"
      timeout="300"
      rounded="circle"
      height="350"
      width="350"
      flat
    >
      <div class="text-center display-1">
        {{ message.text }}
      </div>
    </v-snackbar>

    <v-btn fab dark fixed bottom right class="red" v-if="time"
      ><span color="white" class="text--white display-1">{{
        time
      }}</span></v-btn
    >

    <v-dialog v-model="finished" width="600">
      <v-card class="accent">
        <v-card-text class="text-center pa-5">
          <v-img
            src="../assets/trophy.svg"
            width="100"
            class="ma-auto mb-8"
          ></v-img>
          <div class="display-1">Awesome!</div>

          Completed in {{ timerDisplay }}! <br /><br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary pl-4 pr-4" x-large rounded @click="start"
              >Start new game</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="revealSet" width="400">
      <v-card class="accent">
        <v-card-title class="heading-1">Reveal set</v-card-title>
        <v-card-text class="pt-3 pb-4">
          Are you sure? This will cost you a minute</v-card-text
        >
        <v-card-actions class="pa-5">
          <v-btn text @click="revealSet = false">Nope</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary pl-4 pr-4" rounded @click="reveal"
            >Reveal set</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="restartConfirm" width="400">
      <v-card class="accent">
        <v-card-title class="heading-1">Start new game</v-card-title>
        <v-card-text class="pt-3 pb-4">
          Are you sure? This will end the current game</v-card-text
        >
        <v-card-actions class="pa-5">
          <v-btn text @click="restartConfirm = false">Nope</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary pl-4 pr-4" rounded @click="start"
            >Start new game</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="exitConfirm" width="400">
      <v-card class="accent">
        <v-card-title class="heading-1">Leave game</v-card-title>
        <v-card-text class="pt-3 pb-4">
          Are you sure you want to leave the game?
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn text @click="exitConfirm = false">Nope</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary pl-2 pr-2" rounded to="/">Leave</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import shape from "../components/shape";

export default {
  name: "game",
  props: {},

  components: {
    shape,
  },

  data() {
    return {
      shapes: ["circle", "square", "diamond"],
      colors: ["red", "blue", "limegreen"],
      patterns: ["hatch", "solid", "outline"],
      numbers: [1, 2, 3],

      sidebar: true,

      restartConfirm: false,
      exitConfirm: false,

      revealSet: false,

      flash: false,
      message: { text: "", type: "" },

      finished: false,

      paused: false,

      foundSets: [],
      deck: [],

      time: 0,
      timer: null,

      gameTimer: null,
      gameTime: 0,

      show: 0,

      revealCount: 0,
      openCount: 0,

      seccondsPerFalseOpen: 10,
      secondsPerReveal: 60,
    };
  },

  watch: {
    flash(newVal) {
      if (!newVal) this.message = { text: "", type: "primary" };
    },
  },

  computed: {
    ...mapState(["session"]),

    visible() {
      return this.deck.slice(0, this.show);
    },

    remaining() {
      let r = this.deck.length - this.show;

      return r >= 0 ? r : 0;
    },

    closedDeck() {
      let s = this.remaining - this.session.settings.increment;

      return s >= 0 ? s : 0;
    },

    cardStyle() {
      if (this.session.settings.background)
        return `background-image:url(${require("../assets/" +
          this.session.settings.background +
          ".png")})`;

      return "";
    },

    timerDisplay() {
      return `${("00" + Math.floor(this.gameTime / 60)).slice(-2)}:${(
        "00" + Math.floor(this.gameTime % 60)
      ).slice(-2)}`;
    },

    points() {
      return (
        this.gameTime +
        this.revealCount * this.secondsPerReveal +
        this.openCount * this.seccondsPerFalseOpen
      );
    },
  },

  methods: {
    ...mapMutations({
      saveGame: "SAVE_GAME",
      updateSettings: "UPDATE_SETTINGS",
    }),
    flashMessage(message, type) {
      this.flash = true;
      this.message = { text: message, type: type };
    },

    start() {
      this.newDeck();
      this.shuffleDeck();
      this.foundSets = [];

      this.show = this.session.settings.boardSize;

      this.revealCount = 0;
      this.openCount = 0;

      this.finished = false;
      this.flash = false;
      this.restartConfirm = false;

      if (this.gameTimer) {
        clearInterval(this.gameTimer);
        this.gameTime = null;
      }

      this.gameTimer = setInterval(() => {
        if (!this.paused) this.gameTime++;

        this.$emit("gametime", this.timerDisplay);
      }, 1000);
    },

    icon(card) {
      let i = "mdi-" + card.shape;
      if (card.pattern == "empty" || card.patern == "lines") {
        i = i + "-outline";
      }

      return i;
    },

    openMore() {
      if (this.findSet(this.visible)) {
        // penalize because there are sets available
        this.openCount += this.session.settings.increment;
        this.flashMessage(
          `+${
            this.session.settings.increment * this.seccondsPerFalseOpen
          } seconds`,
          "warning"
        );
      }

      this.show = this.show + Number(this.session.settings.increment);
    },

    findSet(cards) {
      for (let one in cards) {
        for (let two in cards) {
          if (one != two) {
            for (let three in cards) {
              if (two != three) {
                let newSet = [];
                newSet.push(cards[one]);
                newSet.push(cards[two]);
                newSet.push(cards[three]);
                if (this.isSetInternal(newSet)) {
                  return newSet;
                }
              }
            }
          }
        }
      }
      return null;
    },

    reveal() {
      this.revealSet = false;
      let set = this.findSet(this.visible);

      if (set) {
        for (let card of set) {
          card.reveal = true;
          this.shake(card);
        }

        this.playSound("reveal");
      } else {
        this.flashMessage("No set", "error");
      }
    },

    newDeck() {
      this.deck = [];
      for (let f of this.patterns) {
        for (let s of this.shapes) {
          for (let n of this.numbers) {
            for (let c of this.colors) {
              this.deck.push({
                color: c,
                shape: s,
                pattern: f,
                number: n,
                selected: false,
                reveal: false,
              });
            }
          }
        }
      }
    },

    shuffleDeck() {
      let shuffledDeck = [];

      while (this.deck.length) {
        let c = this.deck.splice((Math.random() * 10000) % this.deck.length, 1);
        shuffledDeck.push(c[0]);
      }

      this.deck = shuffledDeck;
    },

    shake(card) {
      card.animated = true;
      setTimeout(() => {
        card.animated = false;
      }, 1000);
    },

    async playSound(sound) {
      if (!this.session.settings.mute) {
        var audio = new Audio(require(`../assets/${sound}.mp3`));
        await audio.play();
      }
    },

    toggleMute() {
      this.session.settings.mute = !this.session.settings.mute;
      this.updateSettings(this.session.settings);
    },

    select(index) {
      this.deck[index].selected = true;
      let selected = this.deck.filter((c) => c.selected);

      if (selected.length == 1) {
        this.time = this.session.settings.timeToGuess;
        this.timer = setInterval(() => {
          this.time--;
          if (this.time <= 0) this.clearSelection();
        }, 1000);
      }

      if (selected.length == 3) {
        // if selections are a match.
        if (this.isSet(selected)) {
          this.foundSets.push(selected);

          // remove from deck
          this.deck = this.deck.filter((c) => !c.selected);

          this.playSound("success");

          if (!this.findSet(this.deck)) {
            this.saveGame({
              name: this.session.name,
              dt: Date.now(),
              duration: this.gameTime,
              opens: this.openCount,
              reveals: this.revealCount,
              points: this.points,
            });

            clearInterval(this.gameTimer);

            this.finished = true;
          }
        } else {
          selected.forEach((c) => this.shake(c));

          this.playSound("failure");
        }

        this.clearSelection();
      }
    },

    isSet(selected) {
      let set = this.isSetInternal(selected);

      if (set) {
        this.flashMessage("Yay!!!!", "success");
        this.show =
          this.show > this.session.settings.boardSize
            ? this.show - Number(this.session.settings.increment)
            : this.session.settings.boardSize;
      } else {
        this.flashMessage("Nope!!!!", "error");
        this.show =
          this.show > this.session.settings.boardSize
            ? this.show - Number(this.session.settings.increment)
            : this.session.settings.boardSize;
      }

      return set;
    },

    isSetInternal(selected) {
      for (let c of this.colors) {
        if (selected.filter((s) => s.color == c).length == 2) return false;
      }
      for (let f of this.patterns) {
        if (selected.filter((s) => s.pattern == f).length == 2) return false;
      }
      for (let sh of this.shapes) {
        if (selected.filter((s) => s.shape == sh).length == 2) return false;
      }
      for (let c of this.numbers) {
        if (selected.filter((s) => s.number == c).length == 2) return false;
      }

      // if found but had been reveal, increment revealcount

      if (selected.every((c) => c.reveal)) this.revealCount++;

      // clear all reveals

      this.deck.forEach((c) => (c.reveal = false));

      return true;
    },

    clearSelection() {
      // check if it's a set, if so, give a point, remove from deck
      clearInterval(this.timer);
      this.timer = null;
      this.time = 0;

      // clear selected
      for (let c in this.deck) {
        this.deck[c].selected = false;
      }
    },
  },

  mounted() {
    if (!this.session.user) {
      this.$router.push("/");
    }

    this.show = this.session.settings.boardSize;
    this.start();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.set-card-small {
  width: 65px;
  height: 90px;
  display: inline-block;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.selected {
  transform: perspective(800px) rotateX(15deg);
  transition: transform 0.3s;
}
</style>
