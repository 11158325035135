<template>
  <game></game>
</template>

<script>
import game from "../components/game";

export default {
  name: "Play",

  components: {
    game,
  },

  data: () => ({}),

  methods: {},
};
</script>

<style scoped></style>
