<template>
  <v-app>
    <ul class="circles">
      <li v-for="n of 10" :key="n"></li>
    </ul>
    <v-toolbar v-if="session.user" class="primary">
      <v-spacer></v-spacer>
      <v-btn disabled small text dark>  {{ session.user.email }}</v-btn>
      <v-btn small text dark @click="logout" fab><v-icon small class="grey--text">mdi-logout</v-icon></v-btn>
    </v-toolbar>

    <div fluid class="area fill-height">
      <v-toolbar
        v-if="$route.path == '/'"
        flat
        dark
        color="transparent absolute"
      >
        <v-spacer></v-spacer>
      </v-toolbar>

      <div class="fill-height" v-if="!session || !session.user">
        <v-card
          :aspect-ratio="1 / 1"
          dark
          style="opacity: 0.75"
          fluid
          width="500"
          class="text-center primary mx-auto mt-10"
        >
          <v-card-text>
            <v-img
              class="ma-auto mt-5"
              src="./assets/logo.png"
              style="width: 40%"
            />

            <v-alert
              dense
              outlined
              class="mt-2 mb-2 caption ml-10 mr-10 primary lighten-2"
              v-if="alert"
            >
              {{ error }}
            </v-alert>

            <div v-if="!session.user">
              <div class="mt-4 mb-4 text-center ml-10 mr-10">
                <v-text-field
                  outlined
                  placeholder="Email"
                  v-model="email"
                  label="Email"
                ></v-text-field>
                <v-text-field
                  outlined
                  placeholder="Password"
                  label="Password"
                  type="password"
                  v-model="password"
                >
                </v-text-field>
                <v-btn
                  @click="login"
                  x-large
                  rounded
                  class="accent secondary--text"
                  width="300"
                  :disabled="!email || !password"
                  >{{ register ? "Create Account" : "Sign in" }}</v-btn
                >
                <v-btn
                  text
                  class="white--text mt-2"
                  @click="register = !register"
                  >{{ register ? "Sign in" : "Create Account" }}</v-btn
                >
                <br />
                OR
              </div>
            </div>
            <v-btn
              @click="startUserSession()"
              x-large
              rounded
              class="accent secondary--text mb-4"
              width="300"
              >Play {{ session.user ? "" : "as guest" }}</v-btn
            >
          </v-card-text>
        </v-card>
      </div>

      <v-main v-else>
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import firebase from "firebase";

export default {
  name: "App",

  data: () => ({
    email: "",
    password: "",

    alert: false,
    error: null,

    register: false,
  }),

  watch: {},

  created() {},

  beforeDestroy() {},

  computed: {
    ...mapState(["session"]),
  },

  mounted() {
    //  this.user = firebase.auth().currentUser;
  },

  methods: {
    ...mapMutations({
      startSession: "START_SESSION",
      endSession: "END_SESSION",
    }),

    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.endSession();
          this.user = null;
        });
    },

    login() {
      if (this.register) {
        firebase
          .auth() // get the auth api
          .createUserWithEmailAndPassword(this.email, this.password) // need .value because ref()
          .then((data) => {
            //         router.push("/feed"); // redirect to the feed
            this.startUserSession(data.user);
            this.register = false;
          })
          .catch((error) => {
            console.log(error.code);
            this.showError(error.message);
          });
      } else {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((data) => {
            this.startUserSession(data.user);
            console.log("logged in");
            //          this.$router.push({ path: "/" });
          })
          .catch((error) => {
            this.user = null;
            this.showError(error);
          });
      }

      /*var playersRef = firebase.database().ref("stats/");

      playersRef.set ({
        John: {
            number: 1,
            age: 30
        },
        
        Amanda: {
            number: 2,
            age: 20
        }
      });*/
    },

    startUserSession(u) {
      const user = u ? u : { email: "Guest" };
      this.startSession({ user: user });
      this.session.user = user;
    },

    showError(msg) {
      this.alert = true;
      this.error = msg;
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
  },
};
</script>

<style>
:root {
  --amplify-secondary-color: #fff;
  --amplify-secondary-contrast: #832161;
  --amplify-primary-color: #edde88;
  --amplify-primary-shade: #edde88;
  --amplify-background-color: #832161;
}

.area {
  background: #bc4d9f;
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>
